<template>
  <div class="offlineCoach">
    <div class="right-top-btn-group">
      <a-button class="right-top-btn" @click="addGroup">新增培训</a-button>
    </div>

    <div class="flex flex-start flex-wrap ph-20 mb-10">
      <div class="flex mr-30 mb-10">
        <span class="flex-none">培训名称：</span>
        <a-input v-model="trainName" placeholder="请输入培训名称" style="width: 160px"/>
      </div>
      <div class="flex mr-30 mb-10">
        <span class="flex-none">类别：</span>
        <a-cascader v-model="trainClass"
                    :options="selectList"
                    :field-names="{ label: 'title', value: 'id', children: 'childs' }"
                    change-on-select
                    placeholder="请选择类别"
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    style="width: 360px;"/>
      </div>
      <div class="flex mr-30 mb-10">
        <span class="flex-none">状态：</span>
        <a-select v-model="selectedStatus"
                  :placeholder="'请选择状态'"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  style="width: 160px">
          <a-select-option v-for="item of statusList"
                           :key="item.id"
                           :value="item.id">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div>
      <div class="flex mr-30 mb-10">
        <span class="flex-none">岗位：</span>
        <app-select-job v-model="postIds"/>
      </div>
      <div class="flex mr-30 mb-10">
        <span class="flex-none">组织机构：</span>
        <app-select-org v-model="orgIds" class="select-org"/>
      </div>

      <div class="flex mr-30 mb-10 ph-20">
        <a-button :disabled="readOnly" type="primary" @click="search()">查询</a-button>
        <a-button @click="reset">重置</a-button>
      </div>
    </div>

    <div class="ph-20 full-width">
      <a-table ref="tableRef"
               :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="loading"
               bordered
               rowKey="id"
               :scroll="{ x: '100%' }"
               class="small-cell-table table">
        <template slot="action" slot-scope="text, record">
          <a-button type="link" @click="toEdit(record.id)">编辑</a-button>
          <a-button type="link" v-if="record.status === 0" @click="toDel(record.id)">删除</a-button>
          <a-button type="link" @click="toPut(record.id, record.status)">
            {{ record.status === 1 ? '下架' : '上架' }}
          </a-button>
          <a-button type="link" @click="toDetail(record)">业务详情</a-button>
          <a-button type="link" @click="onSettle(record)">结算业务</a-button>
          <a-button type="link" @click="onSettleExport(record)">导出结果</a-button>

          <a-button v-if="[0, 3].includes(record.zip_status)" type="link" @click="zipFiles(record)">文件打包</a-button>
          <a-button v-else-if="record.zip_status === 2" type="link">打包中</a-button>
          <template v-else-if="[1].includes(record.zip_status)">
            <a-button type="link" @click="zipFiles(record)">重新打包</a-button>
            <a-button type="link" @click="getZippedFilesUrl(record)">获取下载地址</a-button>
          </template>
        </template>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total"
                      :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"/>
        </template>
      </a-table>
    </div>

    <SelectDMSPeopleModal ref="selectDMSPeopleModalRef" @success="getData(
      pagination.current,
      pagination.pageSize
    )"/>
    <ExportResultModal ref="exportResultModalRef"/>
  </div>
</template>

<script>
import table from '@/mixins/table';
import {
  getTrainList,
  delTrainList,
  postTrainOperate,
  getCategoriesList,
  getSettlement,
} from './api/list';

import {
  zipFiles,
  getZippedFilesUrl,
} from './api';

import Common from '@/utils/Common';

import { trainDetail } from '@/utils/Navs/OfflineNav';
import Pagination, {
  getPagination,
  updatePagination,
} from '@components/Pagination';

import SelectDMSPeopleModal from './components/SelectDMSPeopleModal';
import ExportResultModal from './components/ExportResultModal';

const columns = [
  {
    width: '200px',
    align: 'center',
    dataIndex: 'title',
    key: 'title',
    title: '业务名称',
  },
  {
    width: '140px',
    align: 'center',
    dataIndex: 'category_id_txt',
    key: 'category_id_txt',
    title: '类别',
  },
  {
    width: '420px',
    align: 'center',
    dataIndex: 'post_list',
    key: 'post_list',
    title: '推送岗位',
    autoExpand: true,
  },
  {
    width: '300px',
    align: 'center',
    dataIndex: 'org_list',
    key: 'org_list',
    title: '推送机构',
  },
  {
    width: '150px',
    align: 'center',
    dataIndex: 'start_time',
    key: 'start_time',
    title: '开始时间',
  },
  {
    width: '150px',
    align: 'center',
    dataIndex: 'end_time',
    key: 'end_time',
    title: '结束时间',
  },
  {
    width: '80px',
    align: 'center',
    dataIndex: 'status_txt',
    key: 'status_txt',
    title: '状态',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'zip_status',
    key: 'zip_status',
    title: '打包状态',
    customRender: (text) => ([
      '未打包',
      '打包完成',
      '打包中',
      '无文件可打包'
    ])[text],
  },
  {
    width: '320px',
    fixed: 'right',
    title: '操作',
    align: 'center',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'List',
  mixins: [table],
  components: {
    Pagination,
    SelectDMSPeopleModal,
    ExportResultModal,
  },
  data() {
    return {
      loading: false,
      downloading: false,

      titles: [{ title: '培训配置' }, { title: '培训列表' }],
      selectList: [],
      classList: [
        { id: '1', label: '集中培训' },
        { id: '2', label: '驻店辅导' },
      ],
      statusList: [
        { id: '1', label: '上架' },
        { id: '0', label: '下架' },
      ],
      originColumns: columns,
      data: [],
      readOnly: false,
      pagination: getPagination(),
      postIds: '', //选择岗位id
      orgIds: '', //选择机构id
      trainName: '',
      trainClass: undefined,
      selectedStatus: undefined,
    };
  },
  created() {
    this.getCategoriesList();
    this.getData(1, this.pagination.defaultPageSize);
  },
  methods: {
    search() {
      this.getData();
    },
    reset() {
      this.postIds = '';
      this.orgIds = '';
      this.trainName = '';
      this.trainClass = undefined;
      this.selectedStatus = undefined;
      this.getData();
    },

    /**
     * 获取表格数据
     * @param page
     * @param pageSize
     * @returns {Promise<void>}
     */
    async getData(page, pageSize) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      const params = {
        title: this.trainName || '',
        org_ids: this.orgIds || '',
        post_ids: this.postIds || '',
        category: (this.trainClass?.[this?.trainClass?.length ? this?.trainClass?.length - 1 : 0] ?? '') || '',
        status: this.selectedStatus || '',
      };
      const data = await getTrainList({
        page: page,
        per_page: pageSize,
        ...params,
      }).finally(() => {
        this.loading = false;
      });

      if (!data || data.error_code) {
        this.$message.error(data.message || '查询数据失败');
        return;
      }
      this.data = data?.data || [];
      this.pagination = updatePagination(this.pagination, data?.meta || {});
    },

    /**
     * 获取培训类别列表
     * @returns {Promise<void>}
     */
    async getCategoriesList() {
      const data = await getCategoriesList();
      if (!data || data.error_code) {
        return;
      }
      this.selectList = data?.data || [];
    },

    // 新建培训
    addGroup() {
      const path = '/index/offline/config/basicConfiguration';
      this.$router.push(path);
    },
    // 编辑培训
    toEdit(id) {
      this.$router.push({
        path: '/index/offline/config/basicConfiguration',
        query: { id },
      });
    },
    // 删除业务
    toDel(id) {
      this.$confirm({
        content: () => <div>确认删除？</div>,
        onOk: async () => {
          const data = await delTrainList({ id: id });
          if (!data || data.error_code) {
            this.$message.error(data.message);
            return;
          }
          this.$message.success(data.message);
          this.getData(this.pagination.current);
        },
      });
    },
    // 上架/下架
    toPut(id, status) {
      this.$confirm({
        content: () => <div>确认{status === 0 ? '上架' : '下架'}？</div>,
        onOk: async () => {
          const data = await postTrainOperate({
            id: id,
            status: status === 0 ? 1 : 0,
          });
          if (!data || data.error_code) {
            this.$message.error(data.message);
            return;
          }
          this.$message.success(data.message);
          this.getData(this.pagination.current);
        },
      });
    },
    // 查看详情
    toDetail(record) {
      this.$store.commit('Offline/updateTrainType', record.training_type);
      this.$router.push({
        path: trainDetail[0]?.path,
        query: {
          id: record.id,
        },
      });
    },

    onSettle(record) {
      if (record.settlement_status > 0 && record.settlement_status < 5) {
        this.$message.info('上一次结算结束后，才能开启新的结算');
        return;
      }
      // if (record.training_type === 2) {
      //   this.showSelectDMSPeopleModal(record);
      //   return;
      // }

      this.$confirm({
        title: "提示",
        content: `确认为${ record.title || "该培训" }结算业务？`,
        onOk: () => this.settle({ training_id: record.id }),
      });
    },
    onSettleExport(record) {
      this.showExportResultModal(record);
    },

    showSelectDMSPeopleModal({ id } = {}) {
      if (this.$refs.selectDMSPeopleModalRef?.show) {
        this.$refs.selectDMSPeopleModalRef.show({
          trainingId: id,
        });
      }
    },
    showExportResultModal({ id } = {}) {
      if (this.$refs.exportResultModalRef?.show) {
        this.$refs.exportResultModalRef.show({
          trainingId: id,
        });
      }
    },
    async settle(params) {
      this.loading = true;
      const data = await getSettlement(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '结算失败');
        return;
      }
      this.$message.success(data?.message || '结算成功');
      this.getData(this.pagination.current, this.pagination.pageSize);
    },

    async zipFiles(record) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await zipFiles(record.id).finally(() => {
        this.loading = false;
      });

      if (!data || data.error_code) {
        this.$message.error(data?.message || '打包失败');
        return;
      }
      this.$message.success(data?.message || '打包成功');
      this.getData(this.pagination.current, this.pagination.pageSize);
    },
    async getZippedFilesUrl(record) {
      this.downloading = true;

      const data = await getZippedFilesUrl(record.id);
      if (!data || data.error_code) {
        this.$message.error(data?.message || '下载失败');
        this.loading = false;
        return;
      }

      Common.downloadFile(data.data?.txt_path || '');
      // this.$message.success('下载成功');
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .select-org .select {
  height: auto;
}

.table ::v-deep {
  button {
    padding-left: 2px;
    padding-right: 2px;
  }
}
</style>
