<template>
  <div class="offlineLive">
    <div class="searchForm">
      <div class="item">
        <span>学员名称：</span>
        <a-input-search
          style="width: 160px"
          v-model="stuName"
          placeholder="请输入学员名称"
        />
      </div>
      <div class="item" v-if="type === 1">
        <span>*班次：</span>
        <a-select v-model="trainClass" placeholder="请选择班次" style="width: 160px"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option
            v-for="item of classList"
            :key="item.id"
            :value="item.id"
          >{{ item.title }}
          </a-select-option
          >
        </a-select>
      </div>
      <div class="item" v-if="type === 2">
        <span>*阶段：</span>
        <a-select v-model="stageId" placeholder="请选择阶段" style="width: 160px"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option
            v-for="item of stageList"
            :key="item.id"
            :value="item.id"
          >{{ item.title }}
          </a-select-option
          >
        </a-select>
      </div>
    </div>
    <div class="searchForm">
      <div v-if="trainingType === 2" class="row row-left">
        <div class="col col-left item">
          <span class="title">岗位：</span>
          <SelectJob
            :show-label="false"
            :disabled="readOnly"
            v-model="postIds"
            @select="selectJob"
          ></SelectJob>
        </div>
      </div>

      <div class="row row-left">
        <div class="col col-left">
          <span class="title">*组织机构：</span>
          <SelectOrg
            class="selectOrg"
            :show-label="false"
            :disabled="readOnly"
            v-model="orgIds"
            :max="trainingType === 2 ? 1 : 0"
            :data="trainingType === 2 ? orgList : undefined"
          ></SelectOrg>
        </div>
      </div>
      <div class="row mt-30 ph-20">
        <div class="col col-left">
          <a-button :disabled="readOnly" type="primary" @click="search()"
          >搜索
          </a-button
          >
          <a-button @click="reset">重置</a-button>
        </div>
      </div>
    </div>
    <div class="actionDom">
      <div class="tabBox">
        <a-button :type="homeworkId === item.id?'primary':''" v-for="(item,index) in workTab" :key="index"
                  @click="changeTab(item.id)">
          {{ item.title }}
        </a-button>
      </div>
      <a-button @click="download()">导出</a-button>
    </div>
    <div class="table">
      <a-table :columns="columns" :data-source="data" :pagination="false" :loading="loading" bordered row-key="username"
               :scroll="{ x: 1800 }">
        <span slot="status" slot-scope="text">{{ text === 0 ? '未提交' : '已提交' }}</span>
        <span class="actionBox" slot="action" slot-scope="text, record">
          <a @click="toDetail(record.user_homework_id)">作业详情</a>
        </span>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import SelectOrg from '@/components/select/SelectOrg';
import SelectJob from '@/components/select/SelectJob';
import Common from '@/utils/Common';
import { getHomeworkList, getHomeworkLabel } from '@/api/offline/Details';
import Pagination, { getPagination } from '@components/Pagination';
import { mapState } from 'vuex';
import { getTrainingOrgTree } from './api/api';

export default {
  name: 'offlineLive',
  components: { SelectOrg, SelectJob, Pagination },
  computed: {
    ...mapState({
      trainingType: (state) => state.Offline.trainType,
    }),
  },
  watch: {
    trainingType: {
      immediate: true,
      handler(type) {
        if (+type === 2) {
          this.getTrainingOrgTree();
        }
      },
    },
  },
  data() {
    return {
      id: this.$route.query.id,//培训Id
      type: 0,//培训Type
      workTab: [],//作业tab
      stageList: [],//阶段选项
      classList: [],//班次选项
      columns: [
        {
          width: 120,
          fixed: 'left',
          align: 'center',
          dataIndex: 'truename',
          key: 'truename',
          title: '姓名',
        },
        {
          width: 80,
          align: 'center',
          dataIndex: 'regional_org_name',
          key: 'regional_org_name',
          title: '大区',
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'small_regional_org_name',
          key: 'small_regional_org_name',
          title: '小区',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'orgname',
          key: 'orgname',
          title: '经销商',
        },
        {
          width: 160,
          align: 'center',
          dataIndex: 'idcard',
          key: 'idcard',
          title: '身份证号',
        },
        {
          width: 120,
          align: 'center',
          dataIndex: 'mobile',
          key: 'mobile',
          title: '电话',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'postname',
          key: 'postname',
          title: '岗位',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'classes_name',
          key: 'classes_name',
          title: '班次',
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'status',
          key: 'status',
          title: '当前状态',
          scopedSlots: { customRender: 'status' },
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'sub_date',
          key: 'sub_date',
          title: '提交时间',
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'overdue_days',
          key: 'overdue_days',
          title: '逾期天数',
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'score',
          key: 'score',
          title: '讲师评分',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'summary',
          key: 'summary',
          title: '讲师评价',
        },
        {
          width: 120,
          fixed: 'right',
          title: '操作',
          align: 'center',
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      data: [],
      loading: false,
      readOnly: false,
      pagination: getPagination(),
      postIds: '', //选择岗位id
      orgIds: this.$route.query?.orgId || '', //选择机构id
      stuName: '',//学员姓名
      trainClass: this.$route.query?.classId || undefined,//班次id
      stageId: Number(this.$route.query?.stage) || undefined,//阶段id
      homeworkId: '',//作业id

      orgList: undefined,
    };
  },
  async created() {
    await this.$store.dispatch('Offline/getDetails', this.id);
    this.type = this.$store.state.Offline.trainDetails.training_type;
    this.classList = this.$store.state.Offline.trainDetails.classes_id;
    this.stageList = this.$store.state.Offline.trainDetails.stage;
    if (this.type === 2) {
      this.columns.find(obj => obj.key === 'classes_name').title = '阶段';
    }
  },
  destroyed() {
  },
  methods: {
    /**
     * 辅导业务，获取参与辅导的组织机构
     * @returns {Promise<void>}
     */
    async getTrainingOrgTree() {
      const data = await getTrainingOrgTree({
        training_id: this.id,
      });
      this.orgList = data?.data || undefined;
    },
    // 作业列表
    async getData(page, pageSize, isDownload) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;
      this.loading = true;
      const params = {
        training_id: this.id,
        training_type: this.type,
        truename_or_username_or_idcard: this.stuName,
        org_id_str: this.orgIds,
        post_id_str: this.postIds,
        classes_id_str: this.trainClass,
        stage: this.stageId,
        homework_id: this.homeworkId,
        is_download: isDownload ? isDownload : '',
      };
      const data = await getHomeworkList({
        page: page,
        per_page: pageSize,
        ...params,
      }, isDownload).finally(() => this.loading = false);
      if (!data || data.error_code || isDownload) {
        return;
      }
      this.data = data?.data || [];
      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    // 作业标签
    async getTab() {
      const params = {
        training_id: this.id,
        training_type: this.type,
        classes_id_str: this.trainClass,
        stage: this.stageId,
        org_id_str: this.orgIds,
        post_id_str: this.postIds,
      };
      const data = await getHomeworkLabel(params);
      if (!data || data.error_code) {
        return;
      }
      this.workTab = data?.data || [];
      this.homeworkId = this.workTab.length > 0 ? this.workTab[0].id : '';
      if (this.homeworkId) {
        this.getData();
      } else {
        this.data = [];
        this.pagination = getPagination();
      }
    },
    //切换标签
    changeTab(id) {
      this.homeworkId = id;
      this.getData();
    },
    //选择班次
    selectClasses(event) {
      this.trainClass = event && event.length ? event.join(',') : '';
    },
    //选择岗位
    selectJob(event) {
      this.postIds = event && event.length ? event.join(',') : '';
    },
    // 选择机构
    selectOrg(event) {
      const data = event?.data || [];
      this.orgIds = data.map((i) => i.id).join(',');
    },
    search() {
      if (this.type === 1) {
        if (this.trainClass === undefined) {
          this.$message.info('请选择班次');
          return;
        }
      } else {
        if (!this.stageId) {
          this.$message.info('请选择阶段');
          return;
        }
        if (!this.orgIds) {
          this.$message.info('请选择组织机构');
          return;
        }
      }
      this.getTab();
    },
    reset() {
      this.postIds = '';
      this.orgIds = '';
      this.stuName = '';
      this.trainClass = undefined;
      this.stageId = undefined;
      this.data = [];
      this.workTab = [];
      this.pagination = {};
    },
    // 导出列表
    download() {
      this.getData('', '', 1);
    },
    // 查看详情
    toDetail(id) {
      if (!id) {
        this.$message.info('该学员暂未提交作业');
        return;
      }
      this.$router.push({
        path: '/index/offline/trainWorkDetails',
        query: {
          userHomeworkId: id,
        },
      });
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
  },
};
</script>

<style scoped lang="scss">
.offlineLive {
  width: 100%;

  .searchForm {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 15px 10px 20px;

    .item {
      padding: 0 5px;
      position: relative;
    }
  }

  .actionDom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
  }

  .table {
    width: 100%;
    padding: 10px 15px;

    .actionBox {
      a {
        padding: 0 5px;
      }
    }
  }
}

</style>

