<template>
  <div class="main">
    <commonTitle>每日打卡次数</commonTitle>
    <div v-if="show">
      <div
        class="daka-list"
        v-for="(items, i) in data"
        @click="changeDk(i)"
        :class="nowIndex === i ? 'on' : ''"
        :key="i"
      >
        <div class="left">{{ items.title }}：</div>
        <div class="right">
          <div
            class="flex-box"
            v-for="(item, index) in items.list"
            :key="index"
          >
            <!-- :default-value="moment(item.start,'HH:mm')" -->
            <a-time-picker
              v-model="item.start"
              format="HH:mm"
              :default-value="
                moment(
                  item.clock_start_date ? item.clock_start_date : item.start,
                  'HH:mm'
                )
              "
              @change="
                (value, valueStr) => {
                  changeStart(value, valueStr, index, items.list);
                }
              "
            />
            <span class="plr10">-</span>
            <!-- :default-value="moment(item.end,'HH:mm')" -->
            <!-- :disabledHours="() => disabledEndHours(item)"
              :disabledMinutes="() => disabledEndMinutes(item)" -->
            <a-time-picker
              v-model="item.end"
              format="HH:mm"
              :default-value="
                moment(
                  item.clock_start_date ? item.clock_end_date : item.end,
                  'HH:mm'
                )
              "
              @change="
                (value, valueStr) => {
                  changeEnd(value, valueStr, index, items.list);
                }
              "
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="trainingType === 1" class="daka-list white">
      <div class="left">{{ addressInfo }}：</div>
      <div class="right flex-box">
        <a-input
          placeholder="请输入"
          v-model.trim="address"
          disabled
          style="width: 410px; margin-right: 20px"
        />
        <a-button type="primary" @click="selectClockInAddress">
          {{ title }}
        </a-button>
      </div>
    </div>
    <saveButtons
      ref="saveButtons"
      @handleSubmit="handleSubmit"
      @saveAndNext="saveAndNext"
    ></saveButtons>
    <selectMap
      ref="clockIn"
      :title="title"
      :latLng="latLng"
      @success="onSuccess"
    ></selectMap>
  </div>
</template>

<script>
import moment from 'moment';
import { geocoder } from '@/components/map/api/commonMap';
import { TimePicker } from 'ant-design-vue';
import { getCheckWorkDetail, saveCheckWork } from '@/api/config';
import { ConfigMixin } from './mixins/configMixins';
import { mapState } from 'vuex';

export default {
  name: 'checkWorkAttendanceConfiguration',
  mixins: [ConfigMixin],
  components: {
    saveButtons: () => import('./components/saveButtons'),
    commonTitle: () => import('./components/commonTitle'),
    selectMap: () => import('./components/selectMap'),
    'a-time-picker': TimePicker,
  },
  computed: {
    ...mapState({
      trainingType: (state) => state.Offline.trainType,
    }),
  },
  data() {
    return {
      title: '选择打卡位置',
      addressInfo: '打卡地点详细地址',
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
      },
      form: {},
      inputVisible: false,
      inputValue: '',
      nowIndex: 0,
      data: [
        {
          title: '一次',
          list: [{ start: moment(7, 'HH:mm'), end: moment(18, 'HH:mm') }],
        },
        {
          title: '两次',
          list: [
            {
              start: moment(7, 'HH:mm'),
              end: moment(12, 'HH:mm'),
            },
            {
              start: moment(13, 'HH:mm'),
              end: moment(18, 'HH:mm'),
            },
          ],
        },
        {
          title: '三次',
          list: [
            {
              start: moment(7, 'HH:mm'),
              end: moment(12, 'HH:mm'),
            },
            {
              start: moment(13, 'HH:mm'),
              end: moment(17, 'HH:mm'),
            },
            {
              start: moment(18, 'HH:mm'),
              end: moment(20, 'HH:mm'),
            },
          ],
        },
      ],
      address: '',
      show: false,
      latLng: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.show = false;
      this.loading = true;
      const data = await getCheckWorkDetail(this.$route.query.id).finally(
        () => (this.loading = false),
      );
      if (!data || data.error_code) {
        this.show = true;
        return;
      }
      if (data) {
        let datas = data.data;
        let num = datas.length;
        this.nowIndex = num ? num - 1 : 0;
        let arr = datas.map((item) => {
          return {
            clock_start_date: item.clock_start_date,
            clock_end_date: item.clock_end_date,
            start: null,
            end: null,
            id: item.id,
          };
        });
        if (this?.data?.[num - 1]?.list) {
          num && (this.data[num - 1].list = arr);
        }
        this.show = true;
        this.address = datas?.[0]?.address;
        const point = datas?.[0]?.address_coor?.split(',');
        this.latLng = point;
        this.address_coor = point;
      }
    },
    moment,
    changeStart(time, timeString, i, list) {
      if (i > 0 && list[i].start <= list[i - 1].end) {
        this.$message.error('开始时间不能小于上一次的结束时间');
        list[i].start = list[i - 1].end.clone().add(1, 'h');
      }
      if (i >= 0 && list[i].start >= list[i].end) {
        this.$message.error('开始时间不能大于结束时间');
        list[i].start = list[i].start.clone().add(-1, 'h');
      }
    },
    changeEnd(time, timeString, i, list) {
      if (list[i].start >= list[i].end) {
        this.$message.error('结束时间不能小于开始时间');
        list[i].end = list[i].start.clone().add(1, 'h');
      }
    },
    handleSubmit() {
      if (+this.trainingType === 1 && !this.address_coor) {
        this.$message.error(`请输入${this.addressInfo}！`);
        return;
      }
      if (+this.trainingType === 1 && !this.latLng.length) {
        this.$message.error(`请${this.title}！`);
        return;
      }
      if (this.nowIndex > 2) {
        this.$message.error('请选择打卡次数');
        return;
      }
      let list = this.data[this.nowIndex].list;
      let info = list.map((item) => {
        return {
          clock_start_date: item.start
            ? moment(item.start).format('HH:mm')
            : item.clock_start_date,
          clock_end_date: item.end
            ? moment(item.end).format('HH:mm')
            : item.clock_end_date,
        };
      });
      let params = {
        training_id: this.$route.query.id,
        info: JSON.stringify(info),
      };
      if (this.trainingType === 1) {
        params.address = this.address || '';
        params.address_coor = this.latLng + ''; // 坐标
      }
      this.save(params);
    },
    async save(params) {
      this.loading = true;
      const data = await saveCheckWork(params).finally(
        () => (this.loading = false),
      );

      if (!data || data.error_code) {
        this.$message.error({ content: data?.message || '保存失败' });
        return;
      }
      this.$message.success({ content: data?.message || '保存成功' });
      if (this.trainingType === 2) {
        this.goNextStep('exam');
      } else {
        this.goNextStep('liveClassConfiguration');
      }
    },
    changeDk(i) {
      this.nowIndex = i;
    },
    selectClockInAddress() {
      this.$refs.clockIn.openMe();
    },
    async onSuccess(recode) {
      this.address = recode.addr;
      this.latLng = [recode.lng, recode.lat];
      this.address_coor = [recode.lng, recode.lat];
      if (!recode.addr) {
        const data = await geocoder({
          location: [recode.lat, recode.lng].toString(),
          get_poi: 1,
        });
        if (data?.status === 0) {
          this.address = data?.result?.address;
        }
      }
    },
    disabledStartHours(list, index) {
      let hours = [];
      if (!index) return hours;
      const end = list[index - 1].end;
      if (!end) return hours;
      const hour = moment(end).hour() - 1;
      for (let i = hour; i >= 0; i--) {
        hours.push(i);
      }
      return hours;
    },
    disabledStartMinutes(list, index) {
      let minutes = [];
      if (!index) return minutes;
      const end = list[index - 1].end;
      if (!end) return minutes;
      const minute = moment(end).minute() - 1;
      for (let i = minute; i >= 0; i--) {
        minutes.push(i);
      }
      return minutes;
    },
    disabledEndHours(item) {
      const hours = [];
      if (!item.start) return hours;
      const hour = moment(item.start).hour() - 1;
      for (let i = hour; i >= 0; i--) {
        hours.push(i);
      }
      return hours;
    },
    disabledEndMinutes(item) {
      const minutes = [];
      if (!item.start) return minutes;
      const minute = moment(item.start).minute();
      for (let i = minute; i >= 0; i--) {
        minutes.push(i);
      }
      return minutes;
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 700px;
  margin: 0 auto;
}

.flex-box {
  display: flex;
  padding: 10px 0;
}

.bg-grey {
  background-color: rgb(242, 242, 242);
  padding: 0 15px;

  .rt {
    flex: 1;
  }

  .lt {
    padding-right: 10px;
  }
}

.w550 {
  width: 550px;
}

.daka-list {
  display: flex;
  border: 1px solid #ddd;
  background-color: rgba(0, 0, 0, 0.05);
  margin-top: 10px;
  cursor: default;

  .left {
    width: 150px;
    text-align: right;
    padding: 10px;
    line-height: 50px;
  }

  .right {
    padding: 10px 0;
  }
}

.daka-list.on {
  border: 1px solid #76c8ef;
  background-color: rgba(24, 144, 255, 0.1);
}

.daka-list.white {
  border: 0;
  background: none;
  padding-bottom: 20px;

  .left {
    line-height: 30px;
  }
}

.plr10 {
  padding: 0 10px;
}
</style>
