<template>
  <div class="offlineLive">
    <div class="searchForm">
      <div class="item">
        <span>学员名称：</span>
        <a-input-search
          style="width: 160px"
          v-model="stuName"
          placeholder="请输入学员名称"
        />
      </div>
      <div class="item">
        <span>*班次：</span>
        <a-select v-model="trainClass" placeholder="请选择班次" style="width: 160px"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode">
          <a-select-option
            v-for="item of classList"
            :key="item.id"
            :value="item.id"
          >{{ item.title }}
          </a-select-option
          >
        </a-select>
      </div>
    </div>
    <div class="searchForm">
      <div class="row row-left">
        <div class="col col-left item">
          <span class="title">岗位：</span>
          <SelectJob
            :show-label="false"
            :disabled="readOnly"
            v-model="postIds"
            @select="selectJob"
          ></SelectJob>
        </div>
      </div>

      <div class="row row-left">
        <div class="col col-left">
          <span class="title">组织机构：</span>
          <SelectOrg
            class="selectOrg"
            :show-label="false"
            :disabled="readOnly"
            v-model="orgIds"
            :enabled-depth="trainingType === 2 ? [6] : []"
            :max="trainingType === 2 ? 1 : 0"
          ></SelectOrg>
        </div>
      </div>
      <div class="row mt-30 ph-20">
        <div class="col col-left">
          <a-button :disabled="readOnly" type="primary" @click="search()"
          >搜索
          </a-button
          >
          <a-button @click="reset">重置</a-button>
        </div>
      </div>
    </div>
    <div class="actionDom">
      <a-button @click="download()">导出</a-button>
    </div>
    <div class="table">
      <a-table :columns="newColumns" :data-source="data" :pagination="false" :loading="loading" bordered
               row-key="username" :scroll="{ x: 1800 }">
        <template v-for="item in liveColumns" :slot="'status'+item.key" slot-scope="text">
          <span class="liveStatus" :key="item.key">{{ text[item.key].status === 'start' ? '未完成' : '已完成' }}</span>
        </template>
        <template slot="footer">
          <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import SelectOrg from '@/components/select/SelectOrg';
import SelectJob from '@/components/select/SelectJob';
import Common from '@/utils/Common';
import { getLiveList } from '@/api/offline/Details';
import Pagination, { getPagination } from '@components/Pagination';
import { mapState } from 'vuex';

export default {
  name: 'offlineLive',
  components: { SelectOrg, SelectJob, Pagination },
  computed: {
    ...mapState({
      trainingType: (state) => state.Offline.trainType,
    }),
  },
  data() {
    return {
      id: this.$route.query.id,//培训Id
      type: 1,//培训Type
      classList: [],//班次筛选
      stageList: [],//阶段筛选
      liveColumns: [],
      newColumns: [],
      columns: [
        {
          width: 120,
          fixed: 'left',
          align: 'center',
          dataIndex: 'truename',
          key: 'truename',
          title: '姓名',
        },
        {
          width: 80,
          align: 'center',
          dataIndex: 'regional_org_name',
          key: 'regional_org_name',
          title: '大区',
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'small_regional_org_name',
          key: 'small_regional_org_name',
          title: '小区',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'orgname',
          key: 'orgname',
          title: '经销商',
        },
        {
          width: 160,
          align: 'center',
          dataIndex: 'idcard',
          key: 'idcard',
          title: '身份证号',
        },
        {
          width: 120,
          align: 'center',
          dataIndex: 'mobile',
          key: 'mobile',
          title: '电话',
        },
        {
          width: '',
          align: 'center',
          dataIndex: 'postname',
          key: 'postname',
          title: '岗位',
        },
        {
          width: 100,
          align: 'center',
          dataIndex: 'classes_name',
          key: 'classes_name',
          title: '班次',
        },
      ],
      data: [],
      loading: false,
      readOnly: false,
      pagination: getPagination(),
      postIds: '', //选择岗位id
      orgIds: this.$route.query?.orgId || '', //选择机构id
      stuName: '',//学员姓名
      trainClass: this.$route.query?.classId || undefined,//班次id
    };
  },
  async created() {
    await this.$store.dispatch('Offline/getDetails', this.id);
    this.type = this.$store.state.Offline.trainDetails.training_type;
    this.classList = this.$store.state.Offline.trainDetails.classes_id;
    this.stageList = this.$store.state.Offline.trainDetails.stage;
  },
  destroyed() {
  },
  methods: {
    // 打卡列表
    async getData(page, pageSize, isDownload) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;
      this.loading = true;
      const params = {
        training_id: this.id,
        training_type: this.type,
        truename_or_username_or_idcard: this.stuName,
        org_id_str: this.orgIds,
        post_id_str: this.postIds,
        classes_id: this.trainClass,
        is_download: isDownload ? isDownload : '',
      };
      const data = await getLiveList({
        page: page,
        per_page: pageSize,
        ...params,
      }, isDownload).finally(() => this.loading = false);
      if (!data || data.error_code || isDownload) {
        return;
      }
      this.data = data?.data || [];
      if (this.data.length > 0) {
        const newArr = [];
        const oldArr = JSON.parse(JSON.stringify(this.columns));
        this.data[0].live_info.forEach((item, index) => {
          const params = {
            key: index,
            width: '',
            // fixed: 'right',
            align: 'center',
            dataIndex: 'live_info',
            title: item.title,
            scopedSlots: { customRender: 'status' + index },
          };
          newArr.push(params);
        });
        oldArr.push(...newArr);
        this.liveColumns = newArr;
        this.newColumns = oldArr;
      } else {
        this.newColumns = [];
      }
      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    //选择岗位
    selectJob(event) {
      this.postIds = event && event.length ? event.join(',') : '';
    },
    // 选择机构
    selectOrg(event) {
      const data = event?.data || [];
      this.orgIds = data.map((i) => i.id).join(',');
    },
    search() {
      if (this.trainClass === undefined) {
        this.$message.info('请选择班次');
        return;
      }
      this.getData();
    },
    reset() {
      this.postIds = '';
      this.orgIds = '';
      this.stuName = '';
      this.trainClass = undefined;
      this.data = [];
      this.newColumns = [];
      this.pagination = {};
    },
    // 导出列表
    download() {
      this.getData('', '', 1);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
  },
};
</script>

<style scoped lang="scss">
.offlineLive {
  width: 100%;

  .searchForm {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 15px 10px 20px;

    .item {
      padding: 0 5px;
      position: relative;
    }
  }

  .actionDom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 15px;
  }

  .table {
    width: 100%;
    padding: 5px 15px 10px 20px;

    .actionBox {
      a {
        padding: 0 5px;
      }
    }
  }
}

</style>

