// 培训
export const TRAIN_LIST = [
  {
    value: '1',
    label: '报名前',
  },
  {
    value: '2',
    label: '报名时',
  },
  {
    value: '3',
    label: '报名后',
  },
];
// 辅导
export const COUNSELING = [
  {
    value: '6',
    label: '报名前',
  },
  {
    value: '7',
    label: '辅导中',
  },
  {
    value: '8',
    label: '结束后',
  },
];
export const ALERT_SELECT = [
  {
    value: '1',
    label: '报名前',
  },
  {
    value: '2',
    label: '报名时',
  },
  {
    value: '3',
    label: '报名后',
  },
  {
    value: '6',
    label: '报名前',
  },
  {
    value: '7',
    label: '辅导中',
  },
  {
    value: '8',
    label: '结束后',
  },
];
export const ROLE_SELECT = [
  {
    value: 1,
    label: '讲师',
  },
  {
    value: 2,
    label: '学员',
  },
];
export const TYPE_SELECT = [
  {
    label: '文件',
    value: 'cont',
  },
  {
    label: '图片',
    value: 'img',
  },
  {
    label: '视频',
    value: 'video',
  },
];
